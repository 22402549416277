<script>
export default {
  computed: {
    routeBaseName() {
      return this.getRouteBaseName(this.$route) || ''
    },

    isBlogPost() {
      return this.routeBaseName === 'blog-slug'
    },
  },

  methods: {
    translatedBlogPost(code) {
      if (this.isBlogPost && !this.switchLocalePath(code).includes(this.$route.params.slug)) {
        return this.switchLocalePath(code)
      }

      return this.localePath('blog', code)
    },

    getCurrentRouteToLocaleCode(code) {
      if (!this.$route.name) {
        return this.localePath('/', code)
      }

      if (this.isBlogPost) {
        return this.translatedBlogPost(code)
      }

      return this.switchLocalePath(code)
    },
  },
}
</script>
