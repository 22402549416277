<template>
  <footer
    class="app-footer"
    :class="{
      'is-expanded': isExpanded,
      'is-host-variant': isHostVariant,
      'is-sticky': isSticky,
      'is-compact': isCompact,
      'is-search-page': isRvSearchPage,
    }"
    :style="cssProps"
  >
    <ZContainer>
      <div class="actions">
        <div class="locale">
          <AppLocalization />
          <ZButton
            v-if="!isCompact && isSticky"
            class="d-none d-xl-inline-flex footer-toggle"
            @click="toggleExpand"
          >
            {{ t('supportAndResources') }} <fa icon="chevron-down" />
          </ZButton>
        </div>

        <template v-if="!isHostVariant">
          <div
            v-if="!isCompact"
            class="get-the-app d-xl-none"
          >
            <span>{{ t('guestTitle') }}</span>
            <AppMobileAppDownloadButtons
              btn-size="medium"
              class="download-buttons"
            />
          </div>
        </template>
      </div>

      <nav
        v-if="!isCompact"
        class="links"
      >
        <template v-if="isHostVariant">
          <div
            v-for="(links, key) in menuItemsLocalized"
            :key="key"
          >
            <h4>{{ t(`${key}.title`) }}</h4>

            <ul>
              <li
                v-for="(url, subKey) in links"
                :key="subKey"
              >
                <component
                  :is="isExternal(url) ? 'a' : 'nuxt-link'"
                  :href="localePath(url)"
                  :to="localePath(url)"
                  :target="isExternal(url) ? '_target' : null"
                >
                  {{ t(`${key}.${(subKey as string)}`) }}
                </component>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <div>
            <h4>{{ t('company') }}</h4>
            <ul>
              <li>
                <nuxt-link :to="localePath('about')">
                  {{ t('about') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('blog')">
                  {{ t('blog') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('careers')">
                  {{ t('careers') }}
                </nuxt-link>
              </li>
              <li>
                <a
                  href="https://company-209628.frontify.com/d/qwb7VrkogegN"
                  target="_blank"
                >{{
                  t('brandGuidelines')
                }}</a>
              </li>
              <li>
                <nuxt-link :to="localePath('affiliates')">
                  {{ t('affiliates') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('reviews')">
                  {{ t('reviews') }}
                </nuxt-link>
              </li>
              <li>
                <a
                  href="https://rvezy.store/"
                  target="_blank"
                >{{ t('rvezyStore') }}</a>
              </li>
            </ul>
          </div>

          <div>
            <h4>{{ t('getStarted') }}</h4>
            <ul>
              <li>
                <nuxt-link
                  :to="localePath('rv-search')"
                  prefetch
                >
                  {{ t('searchRVs') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="linkListRv">
                  {{ t('listYourRV') }}
                </nuxt-link>
              </li>
              <li>
                <NuxtLinkLocale :to="ROUTE_BASE_NAME.rv_rentals">
                  {{ t('rvRentals') }}
                </NuxtLinkLocale>
              </li>
              <li>
                <ButtonBuyAnRv
                  :text-btn="t('buyAnRv')"
                  cta="footer"
                  is-link
                  use-primary200
                />
              </li>
              <li>
                <nuxt-link
                  :to="campgroundsUrl"
                  target="_blank"
                >
                  {{ t('searchCampgrounds') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('app-download')">
                  {{ t('downloadRVezyApp') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  :to="localePath('superhost')"
                  @click="handleTrackSuperhostClicked"
                >{{
                  t('superhost')
                }}</nuxt-link>
              </li>
            </ul>
          </div>

          <div>
            <h4>{{ t('helpfulLinks') }}</h4>
            <ul>
              <li>
                <nuxt-link :to="localePath('how-it-works')">
                  {{ t('howItWorks') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('rv-delivery')">
                  {{ t('rvDelivery') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('insurance-and-protection')">
                  {{ t('insuranceAndProtection') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('trust-and-safety')">
                  {{ t('trustAndSafety') }}
                </nuxt-link>
              </li>
              <li>
                <nuxt-link :to="localePath('service-fees')">
                  {{ t('serviceFees') }}
                </nuxt-link>
              </li>
              <li>
                <a
                  :href="t('cancellationPolicyUrl')"
                  @click="() => trackHelpCenterClicked"
                >
                  {{ t('cancellationPolicy') }}
                </a>
              </li>
              <li>
                <nuxt-link :to="localePath('share-your-trip')">
                  {{ t('shareYourTrip') }}
                </nuxt-link>
              </li>
            </ul>
          </div>

          <div>
            <h4>{{ t('support') }}</h4>
            <ul>
              <li>
                <a
                  :href="t('helpCenter.url')"
                  @click="() => trackHelpCenterClicked"
                >
                  {{ t('helpCenter.title') }}
                </a>
              </li>
              <li>
                <a
                  :href="t('contactUs.url')"
                  @click="handleTrackHelpCenterClicked"
                >
                  {{ t('contactUs.title') }}
                </a>
              </li>
            </ul>
          </div>
        </template>
      </nav>

      <template v-if="!isHostVariant">
        <div
          v-if="!isCompact"
          class="social"
        >
          <div class="get-the-app d-none d-xl-block">
            <span>{{ t('guestTitle') }}</span>
            <AppMobileAppDownloadButtons
              btn-size="medium"
              class="download-buttons"
            />
          </div>

          <ul class="social-links">
            <li>
              <a
                href="https://www.facebook.com/rvezy"
                title="RVezy on Facebook"
                target="_blank"
              ><fa :icon="['fab', 'facebook']" /></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/c/RVezy"
                title="RVezy on Youtube"
                target="_blank"
              ><fa :icon="['fab', 'youtube']" /></a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/myrvezy"
                title="RVezy on Instagram"
                target="_blank"
              >
                <fa :icon="['fab', 'instagram']" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/myrvezy"
                title="RVezy on Twitter"
                target="_blank"
              >
                <fa :icon="['fab', 'x-twitter']" />
              </a>
            </li>
            <li>
              <a
                href="https://ca.linkedin.com/company/rvezy.com"
                title="RVezy on Linkedin"
                target="_blank"
              >
                <fa :icon="['fab', 'linkedin-in']" />
              </a>
            </li>
          </ul>

          <VerifiedReviews class="verified-reviews" />
        </div>
      </template>

      <div class="copyright">
        <p>&copy; {{ t('copyrights', { currentYear }) }}</p>
        <div class="terms">
          <nuxt-link :to="localePath('terms-service')">
            {{ t('terms') }}
          </nuxt-link>
          <nuxt-link :to="localePath('privacy-policy')">
            {{ t('privacy') }}
          </nuxt-link>
        </div>
      </div>
    </ZContainer>
  </footer>
</template>

<script setup lang="ts">
import { ROUTE_BASE_NAME } from '~/constants'
import { trackHelpCenterClicked, trackSuperhostLearnMoreClicked } from '~/lib/tracking'

const { $moment } = useNuxtApp()
const { linkListRv } = useOwnerCommon()
const { user } = useAuthentication()
const localePath = useLocalePath()
const { routeBaseName, isPaymentPage, isRvRentalPage, isRvSearchPage, isRequestToBookPage } = useBaseName()
const { t, locale } = useI18n()
const { getCampgroundsUrl } = useCampgroundsUrl()

const props = defineProps({
  isSticky: {
    type: Boolean,
    default: false,
  },

  isHostVariant: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref(false)
const menuItems = ref({
  en: {
    'host-links-support': {
      'help-center': 'https://help.rvezy.com/hc/en-us',
      'how-it-works': '/how-it-works#how-to-list-your-rv',
      'service-fees': '/service-fees',
      'cancellation-policy':
              'https://help.rvezy.com/hc/en-us/articles/13562362996891-What-is-the-cancellation-policy-for-RV-hosts-',
      'contact-us': 'https://help.rvezy.com/hc/en-us/requests/new',
    },
    'host-links-insurance': {
      'overview-for-hosts': '/insurance-and-protection',
      'what-is-covered-not-covered':
              'https://help.rvezy.com/hc/fr-ca/sections/1260800195829-%C3%80-propos-de-la-couverture-d-assurance-de-RVezy',
      'border-crossing-coverage':
              'https://help.rvezy.com/hc/fr-ca/articles/360039340074-Est-ce-que-mon-VR-est-assur%C3%A9-aux-%C3%89tats-Unis-ou-au-Mexique-',
      'what-is-the-insurance-application':
              'https://help.rvezy.com/hc/fr-ca/articles/360039781573-Qu-est-ce-que-la-demande-d-assurance-',
      'restricted-locations':
              'https://help.rvezy.com/hc/fr-ca/articles/360042081374-Y-a-t-il-des-zones-de-voyage-restreintes-ou-interdites-',
    },
    'host-links-hosting': {
      'listing-your-rv': '/owner',
      'learn-about-new-features': '/category/product-blog',
      'getting-a-5-star-review': '/blog/how-to-get-5-star-reviews-everytime',
      'getting-paid-as-a-host':
              'https://help.rvezy.com/hc/en-us/articles/360038105934-How-and-when-do-I-get-paid-',
    },
  },
  fr: {
    'host-links-support': {
      'help-center': 'https://help.rvezy.com/hc/fr-ca',
      'how-it-works': '/how-it-works#how-to-list-your-rv',
      'service-fees': '/service-fees',
      'cancellation-policy':
              'https://help.rvezy.com/hc/fr-ca/articles/13562362996891-Quelle-est-la-politique-d-annulation-pour-les-h%C3%B4tes-de-VR-',
      'contact-us': 'https://help.rvezy.com/hc/fr-ca/requests/new',
    },
    'host-links-insurance': {
      'overview-for-hosts': '/insurance-and-protection',
      'what-is-covered-not-covered':
              'https://help.rvezy.com/hc/fr-ca/sections/1260800195829-%C3%80-propos-de-la-couverture-d-assurance-de-RVezy',
      'border-crossing-coverage':
              'https://help.rvezy.com/hc/fr-ca/articles/360039340074-Est-ce-que-mon-VR-est-assur%C3%A9-aux-%C3%89tats-Unis-ou-au-Mexique-',
      'what-is-the-insurance-application':
              'https://help.rvezy.com/hc/fr-ca/articles/360039781573-Qu-est-ce-que-la-demande-d-assurance-',
      'restricted-locations':
              'https://help.rvezy.com/hc/fr-ca/articles/360042081374-Y-a-t-il-des-zones-de-voyage-restreintes-ou-interdites-',
    },
    'host-links-hosting': {
      'listing-your-rv': '/owner',
      'learn-about-new-features': '/category/product-blog',
      'getting-a-5-star-review': '/blog/how-to-get-5-star-reviews-everytime',
      'getting-paid-as-a-host':
              'https://help.rvezy.com/hc/fr-ca/articles/360038105934-Comment-et-quand-suis-je-pay%C3%A9-',
    },
  },
})

const menuItemsLocalized = computed(() => {
  return menuItems.value[locale.value as 'en' | 'fr']
})

const isCompact = computed(() => {
  return isRequestToBookPage.value || isPaymentPage.value
})

const isExpanded = computed(() => {
  return isOpen.value && !isCompact.value
})

const cssProps = computed(() => {
  return {
    '--footer-gap': isCompact.value ? '1.5rem' : '2.5rem',
    '--footer-padding': isCompact.value ? '1.5rem' : '2.5rem 0',
    '--footer-margin-bottom': (isCompact.value && !isPaymentPage.value) || isRvRentalPage.value ? '116px' : '0',
  }
})

useHead({
  bodyAttrs: {
    class: props.isSticky ? 'footer-padding' : '',
  },
})

const currentYear = computed(() => {
  return $moment().year()
})

const campgroundsUrl = computed(() => getCampgroundsUrl())

function handleTrackHelpCenterClicked() {
  trackHelpCenterClicked({
    pageSource: routeBaseName.value,
  })
}

function handleTrackSuperhostClicked() {
  trackSuperhostLearnMoreClicked({
    accountCountry: user.value?.CountryString?.toLowerCase(),
    isSuperhost: user.value?.SuperHost?.IsActive,
    pageSource: routeBaseName.value,
  })
}

function toggleExpand() {
  isOpen.value = !isOpen.value
}

function isExternal(url: string) {
  if (url.includes('http')) return true
}
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: getColor('primary-25');
  padding: var(--footer-padding);
  margin-bottom: var(--footer-margin-bottom);
  border-top: 1px solid getColor('primary-100');

  @include media-min-size(large) {
    margin-bottom: 0;
  }

  .zcontainer {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: var(--footer-gap);

    @include media-min-size(xLarge) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 1rem;
    }
  }

  .actions {
    .locale {
      @include media-min-size(xLarge) {
        display: flex;
        justify-content: space-between;
        gap: 3rem;
      }

      .footer-toggle {
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }

        svg {
          transition: transform 0.25s ease-in-out;
          color: getColor('highlight-500');
        }
      }
    }

    .get-the-app {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;

      margin-top: 1.5rem;

      font-size: 0.875rem;
      color: getColor('primary-500');

      @include media-min-size(medium) {
        margin-top: 0;
      }
    }

    @include media-min-size(medium) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include media-min-size(xLarge) {
      grid-column: span 6 / span 6;
      order: 2;
      justify-self: end;

      padding: 1rem 0;
    }
  }

  .links {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.25rem;

    @include media-min-size(medium) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include media-min-size(xLarge) {
      grid-column: span 8 / span 8;
      order: 4;

      padding: 1rem 0;
    }
  }

  &.is-host-variant {
    .links {
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @include media-min-size(medium) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media-min-size(xLarge) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column: 1 / -1;
      }
    }
  }

  &.is-sticky {
    @include media-min-size(xLarge) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 50;
      padding: 0;

      transition: transform 0.25s ease-in-out;
      transform: translateY(calc(100% - 58px));

      &.is-expanded {
        transform: translateY(0);

        .footer-toggle svg {
          transform: rotate(0);
        }
      }

      .footer-toggle svg {
        transform: rotate(180deg);
      }

      .actions,
      .copyright {
        padding: 0.5rem 0;
      }
    }
  }

  &.is-compact {
    @include media-min-size(large) {
      padding: 0 var(--footer-padding);
    }
  }

  &.is-search-page {
    @include media-min-size(large) {
      padding-left: 0;
      padding-right: 0;

      :deep(.zcontainer) {
        padding: 0 2.8125rem; // This magic number is used on the serach page.
        margin: 0;
        max-width: none;
      }
    }
  }

  .social {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include media-min-size(medium) {
      flex-direction: row;
      gap: 0;
      justify-content: space-between;
    }

    @include media-min-size(xLarge) {
      flex-direction: column;
      gap: 2.5rem;
      justify-content: initial;

      grid-column: span 4 / span 4;
      order: 3;

      padding: 1rem 0;
    }
  }

  .social-links {
    display: flex;
    gap: 1.5rem;

    a {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      color: getColor('primary-500');

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .copyright {
    font-size: 0.75rem;
    color: getColor('primary-350');

    @include media-min-size(medium) {
      display: flex;
      align-items: center;
    }

    @include media-min-size(xLarge) {
      font-size: 0.875rem;
      padding: 1rem 0;
      grid-column: span 6 / span 6;
      order: 1;
    }

    p {
      margin: 0;
    }

    .terms {
      margin-top: 0.75rem;

      @include media-min-size(medium) {
        margin-left: 1.5rem;
        margin-top: 0;
      }

      a:first-child {
        padding-right: 0.5rem;
        border-right: 1px solid getColor('primary-350');

        @include media-min-size(medium) {
          text-align: right;
        }
      }

      a:last-child {
        padding-left: 0.5rem;
      }
    }
  }

  .download-buttons {
    @include media-min-size(xLarge) {
      margin-top: 0.25rem;
    }
  }

  h4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-transform: none;

    margin: 0;
  }

  ul {
    list-style: none;
    margin: 0.25rem 0 0 0;
    padding: 0;
  }

  a {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: getColor('primary-350');

    @include media-min-size(xLarge) {
      font-size: 0.875rem;
    }
  }

  .zbtn {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    color: getColor('primary-500');

    padding-left: 0;
    padding-right: 0;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}
</style>

<i18n src="~/locales/common/mobile-app.json" lang="json" />

<i18n src="~/locales/common/app.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "about": "About",
    "affiliates": "Affiliates",
    "blog": "Blog",
    "brandGuidelines": "Brand guidelines",
    "cancellationPolicy": "Cancellation policy",
    "cancellationPolicyUrl": "https://help.rvezy.com/hc/en-us/articles/360037736674",
    "careers": "Careers",
    "company": "Company",
    "copyrights": "{currentYear} RVezy. All rights reserved.",
    "downloadRVezyApp": "Download the RVezy App",
    "superhost": "Superhost",
    "getStarted": "Get started",
    "helpfulLinks": "Helpful links",
    "howItWorks": "How it works",
    "insuranceAndProtection": "Insurance and protection",
    "listYourRV": "List your RV",
    "privacy": "Privacy",
    "reviews": "Reviews",
    "rvezyStore": "RVezy Store",
    "rvDelivery": "RV delivery",
    "rvRentals": "RV Rentals",
    "searchCampgrounds": "Search campgrounds",
    "searchRVs": "Search RVs",
    "serviceFees": "Service fees",
    "support": "Support",
    "supportAndResources": "Support and resources",
    "terms": "Terms",
    "trustAndSafety": "Trust and safety",
    "shareYourTrip": "Share your trip",
    "host-links-support": {
      "title": "Support",
      "help-center": "Help center",
      "how-it-works": "How it works",
      "service-fees": "Service fees",
      "cancellation-policy": "Cancellation policy",
      "contact-us": "Contact us"
    },
    "host-links-insurance": {
      "title": "Insurance Information",
      "overview-for-hosts": "Overview for hosts",
      "what-is-covered-not-covered": "What is covered/not covered?",
      "border-crossing-coverage": "Border crossing coverage",
      "what-is-the-insurance-application": "What is the Insurance Application?",
      "restricted-locations": "Restricted locations"
    },
    "host-links-hosting": {
      "title": "Hosting",
      "listing-your-rv": "Listing your RV",
      "learn-about-new-features": "Learn about new features",
      "getting-a-5-star-review": "Getting a 5-star review",
      "getting-paid-as-a-host": "Getting paid as a host"
    }
  },
  "fr": {
    "about": "À propos",
    "affiliates": "Programme d’affiliation",
    "blog": "Blogue",
    "brandGuidelines": "Directives de marque",
    "careers": "Carrières",
    "cancellationPolicy": "Politique d’annulation",
    "cancellationPolicyUrl": "https://help.rvezy.com/hc/fr-ca/articles/360037736674",
    "company": "Entreprise",
    "copyrights": "{currentYear} RVezy. Tous droits réservés.",
    "downloadRVezyApp": "Télécharger l’App Rvezy",
    "superhost": "Superhôtes",
    "getStarted": "Pour démarrer",
    "helpfulLinks": "Liens utiles",
    "howItWorks": "Comment ça marche",
    "insuranceAndProtection": "Assurances et protection",
    "listYourRV": "Inscrire un VR",
    "privacy": "Confidentialité",
    "reviews": "Évaluations",
    "rvezyStore": "Magasin RVezy",
    "rvDelivery": "Livraison de VR",
    "rvRentals": "Locations de VR",
    "searchCampgrounds": "Rechercher un camping",
    "searchRVs": "Rechercher un VR",
    "serviceFees": "Frais de service",
    "support": "Support",
    "supportAndResources": "Support et ressources",
    "terms": "Termes",
    "trustAndSafety": "Confiance et sécurité",
    "shareYourTrip": "Partagez votre voyage",
    "host-links-support": {
      "title": "Support",
      "help-center": "Centre d’aide",
      "how-it-works": "Comment ça fonctionne ",
      "service-fees": "Frais de service",
      "cancellation-policy": "Politique d’annulation",
      "contact-us": "Nous contacter"
    },
    "host-links-insurance": {
      "title": "Informations sur l’assurance",
      "overview-for-hosts": "Aperçu pour les hôtes",
      "what-is-covered-not-covered": "Ce qui est couvert/non couvert?",
      "border-crossing-coverage": "Couverture hors frontière",
      "what-is-the-insurance-application": "Qu’est-ce qu’est la demande d’assurance?",
      "restricted-locations": "Zones restreintes"
    },
    "host-links-hosting": {
      "title": "Section hôtes",
      "listing-your-rv": "Afficher votre VR",
      "learn-about-new-features": "Découvrir les nouvelles fonctionnalités",
      "getting-a-5-star-review": "Obtenir un avis de 5 étoiles",
      "getting-paid-as-a-host": "Être payé en tant qu’hôte"
    }
  }
}
</i18n>
