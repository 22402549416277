<template>
  <button
    :class="['znavbar-toggler']"
    type="button"
    role="button"
    aria-label="Toggle navigation"
    @click.prevent="$emit('toggle')"
  >
    <slot />
  </button>
</template>
