<template>
  <icon-svg />
</template>

<script>
import iconSvg from '~/assets/images/search-icon.svg'

export default {
  components: {
    IconSvg: iconSvg,
  },
}
</script>
