<template>
  <component
    :is="tag"
    class="znavbar-brand"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components'

const attrs = useAttrs()
const tag = computed(() => (attrs.to ? NuxtLink : 'div'))
</script>
