<template>
  <div>
    <ClientOnly>
      <OverlayDrawer
        modal-class="search-drawer"
        :visible="showMobileSearchForm"
        position="top"
        hide-header
        under-app-header
        no-teleport
        @close="showMobileSearchForm = false"
      >
        <LazySearchForm
          v-if="mobileSearchFormLoaded"
          show-search-button
          prevent-overlay-control-scroll
          @submit="showMobileSearchForm = false"
        />
      </OverlayDrawer>
    </ClientOnly>

    <div class="container">
      <Transition name="fade">
        <SearchMobileFormButtonSkeleton
          v-if="isLoading"
          key="loader"
        />
        <SearchMobileFormButton
          v-else
          key="content"
          @click="openMobileSearch()"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobileSearchFormLoaded: false,
      showMobileSearchForm: false,
      isLoading: true,
    }
  },

  mounted() {
    this.isLoading = false
  },

  methods: {
    openMobileSearch() {
      this.mobileSearchFormLoaded = true
      this.showMobileSearchForm = !this.showMobileSearchForm
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 3.125rem;
}
</style>
