<template>
  <div class="skeleton" />
</template>

<style lang="scss" scoped>
.skeleton {
  position: absolute;
  top: 0;
  display: flex;
  background-color: getColor('primary-50');
  color: getColor('primary-500');
  line-height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid getColor('primary-100');
  padding: 0.25rem 0.5rem;
  align-items: center;
  width: 100%;
  height: 3.125rem;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: getColor('primary-50');
  }
  100% {
    background-color: getColor('primary-100');
  }
}
</style>
