<template>
  <ZDropdown
    v-if="user"
    nav
    data-testid="profile-edit"
  >
    <template #button-content>
      <ZAvatar
        :text="initials"
        size="sm"
        :src="user.Avatar"
      />
    </template>
    <ZDropdownItem
      :to="localePath({ name: 'profile-id', params: { id: user.Id }, query: { cta: 'header-nav-avatar' } })"
      data-testid="my-profile"
    >
      {{ $t('pageLink.profile') }}
    </ZDropdownItem>
    <ZDropdownItem
      :to="localePath('dashboard-profile')"
      data-testid="profile"
    >
      {{ $t('pageLink.account') }}
    </ZDropdownItem>
    <ZDropdownItem
      class="logout"
      data-testid="profile-logout"
      @click.prevent="logout()"
    >
      {{ $t('actions.logOut') }}
    </ZDropdownItem>
  </ZDropdown>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { user, logOut } = useAuthentication()
const { initials } = useUser()

async function logout() {
  await logOut()
}
</script>
