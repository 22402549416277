<template>
  <nav
    :class="['znavbar', expandClass]"
  >
    <slot />
  </nav>
</template>

<script setup lang="ts">
import type { NavbarBreakpoint } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  togglerBreakpoint?: NavbarBreakpoint
}>(), {
  togglerBreakpoint: '',
})

const expandClass = computed(() => props.togglerBreakpoint ? `znavbar-expand-${props.togglerBreakpoint}` : 'znavbar-expand')
</script>
