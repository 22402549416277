<template>
  <button
    class="search-drawer-toggle"
    type="button"
  >
    <IconSearch />
    <span class="text">
      <span class="location-text"><SearchLocationText /></span>
      <span class="dates-guests-text"><SearchDatesText /> &bull; <SearchGuestsText /></span>
    </span>
  </button>
</template>

<style lang="scss" scoped>
.search-drawer-toggle {
  display: flex;
  background-color: getColor('primary-50');
  color: getColor('primary-500');
  line-height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid getColor('primary-100');
  padding: 0.25rem 0.5rem;
  align-items: center;
  width: 100%;

  svg {
    width: 2rem;
    height: auto;
    flex-shrink: 0;
  }

  .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  .location-text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .dates-guests-text {
    display: block;
    font-size: 0.75rem;
    color: getColor('primary-400');
  }
}
</style>
